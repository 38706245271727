import * as React from "react"
import Layout from "../components/common/layout"
import Hero from "../components/hero"
import TextMedia from "../components/text-media"
import Title from "../components/title"
import StatsActivity from "../components/stats-activity"
import {  graphql } from "gatsby"
import PreFooter from "../components/pre-footer"
import { Helmet } from 'react-helmet'
import ListLogo from "../components/list-logo"
import SubMenu from "../components/common/sub-menu"
// markup 
const PartnerShips = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const dataPrefooter = _data.find((item: any) => item.block === 'prefooter')
  const dataTextMedia = _data.find((item: any) => item.block === 'text-media')
  const dataTitle = _data.find((item: any) => item.block === 'title')
  const hero = _data.find((item: any) => item.block === 'hero')
  const statsActivity = _data.find((item: any) => item.block === 'stats-activity')
  const logos = _data.find((item: any) => item.block === 'logos')
  const subMenu = _data.find((item: any) => item.block === 'sub-menu')
  console.log(subMenu);
  return (
    <Layout>
      <Helmet
        title='Parnerships'
      />
      <SubMenu data={subMenu.data[0]}></SubMenu>
      <Hero background="bg-blue-white" data={hero.data[0]} container={true} ></Hero>
      <Title data={dataTitle.data[0]} background="bg-white" ></Title>
      <StatsActivity data={statsActivity.data[0]}  background="bg-blue-bale"></StatsActivity>
      <Title data={dataTitle.data[1]}  background="bg-white"></Title>
      <TextMedia data={dataTextMedia.data[0]}   background="bg-white" ></TextMedia>
      <TextMedia data={dataTextMedia.data[1]} reverse={true}  background="bg-white" ></TextMedia>
      <TextMedia data={dataTextMedia.data[2]}    background="bg-white" ></TextMedia>   
      <TextMedia data={dataTextMedia.data[3]}  reverse={true}   background="bg-white" ></TextMedia> 
      <ListLogo data={logos.data[0]} background="bg-white" readMore={false}></ListLogo>  
      <PreFooter data={dataPrefooter.data[0]}  background="bg-blue"></PreFooter> 
     
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["partnerships"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            sub_menu{
              sub_menu_cta {
                text
                link
              }
              title
              links {
                id
                text
                link
                active
              }
            }
            hero {
              hight_light
              content
              images {
                id
                url
                width
              }
              title
              title_top
            }
            title_data {
              title
              content
            }
            stats {
              stats_activity_title
              stats_link {
                text
                url
              }
              stats_activity {
                id
                activity_title
                activity_image
                activity_num
              }
            }
            text_media {
              title
              content
              image
              video {
                thumbnail
                title
                video
              }
              links{
                id
                text
                url
              }
            }
            cards {
              id
              title
              image
              link
            }
            prefooter_data {
              content
              title
              links {
                id
                text
                url
              }
            }
            logo {
              link
              title
              images {
                id
                url
              }
            }
          }
         
        }
      }
    }
  }
`
export default PartnerShips
