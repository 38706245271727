import * as React from 'react'
import Slider from 'react-slick';
import { Link } from 'gatsby'
import CardImage from './common/card-image'
import styled from 'styled-components'
import { breakpointLg} from '../design/responsive/breakpoint'
const ReadMore = styled.a`
    font-weight: bold;
    margin-top: 4rem;
    span {
        line-height: 1.5;
    }
`;
const Block = styled.section`
    @media(min-width: ${breakpointLg}) {
        padding-top: 4rem!important;
    }
`;
const ListLogo = (props: any) => {
    const data = props.data.logo
    console.log(data)
    const settings = {
        peed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        rows: 1,
        autoplay: false,
        dots: false,
        cssEase: 'linear',
        autoplaySpeed: 5000, 
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            }
        ]
    };
    return (
        <>
            <Block className={'tpl-block list-logos text-center ' + props.background}  >
                <div className='container'>
                    <h4 className='mb-lg-5 pb-3'>{data.title}</h4>
                    <Slider {...settings} className='d-flex align-items-center justify-content-center'>
                        {
                            data.images.map((image: any)=>(
                                <Link  to='' key={image.id}>
                                    <CardImage ratio='1x1'  src={image.url} alt= ''/>
                                </Link>   
                            ))
                        }
                    </Slider>     
                    {
                        props.readMore ? (
                            <ReadMore href={data.link} className=' text-decoration-none d-inline-flex alig-items-center'>
                                Learn about our partnerships<span className='ms-2'>➜</span>
                            </ReadMore>
                        ) : null
                    }  
                    
                </div>
            </Block> 
        </>
    )
}

export default ListLogo
